"use client";

import ErrorPage from "@/components/ErrorPage";

/**
 * Catch-all error handler.
 *
 * @see https://nextjs.org/docs/app/building-your-application/routing/error-handling
 */
export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  return <ErrorPage error={error} reset={reset} />;
}
